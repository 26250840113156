import React, { ChangeEvent, useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Step2PriceSelectionProps {
  priceType: string;
  routeCost: number | string;
  onPriceTypeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRouteCostChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onNext: () => void;
  onBack: () => void;
}

const Step2PriceSelection: React.FC<Step2PriceSelectionProps> = ({
  priceType,
  routeCost,
  onPriceTypeChange,
  onRouteCostChange,
  onNext,
  onBack,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const handleNext = () => {
    if (!routeCost) {
      setError(t('campo_obrigatorio'));
      return;
    }
    setError(null);
    onNext();
  };

  return (
    <>
      <FormControl component="fieldset" sx={{alignItems: "center"}}>
        <RadioGroup row aria-label="priceType" name="priceType" value={priceType} onChange={onPriceTypeChange}>
          <FormControlLabel value="tonnePerCubicMeter" control={<Radio />} label={t('taxa_por_ton_metro')} />
          <FormControlLabel value="container" control={<Radio />} label={t('preco_por_container')} />
        </RadioGroup>
      </FormControl>
      {priceType && (
        <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem', marginLeft: '1rem', marginRight: '1rem' }}>
          {priceType === 'container' && (
            <Typography variant="body1">
              <strong>{t('preco_por_container')}:</strong> {t('descricao_preco_por_container')}
            </Typography>
          )}
          {priceType === 'tonnePerCubicMeter' && (
            <Typography variant="body1">
              <strong>{t('taxa_por_ton_metro')}:</strong> {t('descricao_taxa_por_ton_metro')}
            </Typography>
          )}
        </Paper>
      )}
      <TextField
        label={priceType === 'container' ? t('preco_por_container') : t('taxa_por_ton_metro')}
        variant="outlined"
        value={routeCost}
        onChange={onRouteCostChange}
        fullWidth
        margin="normal"
        required
        type="number"
        inputMode="decimal"
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
        error={!!error}
        helperText={error}
        sx={{width:"100%", alignItems: "center"}}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={onBack}>
          {t('voltar')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext} disabled={!routeCost}>
          {t('continuar')}
        </Button>
      </Box>
    </>
  );
};

export default Step2PriceSelection;
