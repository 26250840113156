// src/components/molecules/ContainerSelector.tsx
import React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { containerTypes } from '../../data/containerTypes';

interface ContainerSelectorProps {
  selectedContainer: number;
  onSelectContainer: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContainerSelector: React.FC<ContainerSelectorProps> = ({ selectedContainer, onSelectContainer }) => {
  return (
    <TextField
      select
      label="Tipo de Contêiner"
      value={selectedContainer}
      required
      onChange={onSelectContainer}
      fullWidth
      variant="outlined"
      margin="normal"
    >
      {containerTypes.map(container => (
        <MenuItem key={container.id} value={container.id}>
          {container.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ContainerSelector;
