import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import pt from './pt.json';
import es from './es.json';
import cn from './cn.json';
import fr from './fr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    pt: { translation: pt },
    cn: { translation: cn },
    fr: { translation: fr },
    es: { translation: es }
  },
  lng: 'en', // Idioma padrão
  fallbackLng: 'en', // Idioma fallback
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
