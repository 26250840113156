// src/data/packageTypes.ts
export const packageTypes = [
    {
      id: 1,
      name: "Caixa de Papelão",
      variations: [
        { size: "Pequena (30x30x30cm)", length: 0.3, width: 0.3, height: 0.3, maxWeight: 10 },
        { size: "Média (60x40x40cm)", length: 0.6, width: 0.4, height: 0.4, maxWeight: 20 },
        { size: "Grande (100x60x60cm)", length: 1.0, width: 0.6, height: 0.6, maxWeight: 50 },
        { size: "Customizado" },
      ],
    },
    {
      id: 2,
      name: "Palete",
      variations: [
        { size: "Europalete (120x80x15cm)", length: 1.2, width: 0.8, height: 0.15, maxWeight: 1500 },
        { size: "Palete Padrão (120x100x15cm)", length: 1.2, width: 1.0, height: 0.15, maxWeight: 2000 },
        { size: "Customizado" },
      ],
    },
    {
      id: 3,
      name: "Caixa de Madeira",
      variations: [
        { size: "Pequena (50x50x50cm)", length: 0.5, width: 0.5, height: 0.5, maxWeight: 30 },
        { size: "Média (100x80x60cm)", length: 1.0, width: 0.8, height: 0.6, maxWeight: 100 },
        { size: "Grande (120x100x80cm)", length: 1.2, width: 1.0, height: 0.8, maxWeight: 150 },
        { size: "Customizado" },
      ],
    },
    {
      id: 4,
      name: "Contentores de Plástico",
      variations: [
        { size: "Pequeno (40x30x20cm)", length: 0.4, width: 0.3, height: 0.2, maxWeight: 10 },
        { size: "Médio (60x40x30cm)", length: 0.6, width: 0.4, height: 0.3, maxWeight: 25 },
        { size: "Grande (80x60x40cm)", length: 0.8, width: 0.6, height: 0.4, maxWeight: 50 },
        { size: "Customizado" },
      ],
    },
    {
      id: 5,
      name: "Big Bag",
      variations: [
        { size: "Padrão (90x90x90cm)", length: 0.9, width: 0.9, height: 0.9, maxWeight: 1000 },
        { size: "Grande (100x100x100cm)", length: 1.0, width: 1.0, height: 1.0, maxWeight: 1200 },
        { size: "Customizado" },
      ],
    },
    {
      id: 6,
      name: "Tambor",
      variations: [
        { size: "200 Litros (85x60x60cm)", length: 0.85, width: 0.6, height: 0.6, maxWeight: 200 },
        { size: "100 Litros (70x45x45cm)", length: 0.7, width: 0.45, height: 0.45, maxWeight: 100 },
        { size: "Customizado" },
      ],
    },
  ];
  