// src/data/containerTypes.ts
export const containerTypes = [
  { id: 1, name: "Dry Box - 20 PÉS", cbm: 33.2, maxWeight: 28200, length: 5.9, width: 2.35, height: 2.39, img:"images/DRY_20.png" },
  { id: 2, name: "Dry Box - 40 PÉS", cbm: 67.7, maxWeight: 30480, length: 12.03, width: 2.35, height: 2.39, img:"images/dry_40.png" },
  { id: 3, name: "Reefer - 20 PÉS", cbm: 28.4, maxWeight: 27600, length: 5.44, width: 2.29, height: 2.27, img:"images/REEFER_20_40.png" },
  { id: 4, name: "Reefer - 40 PÉS", cbm: 59.3, maxWeight: 28600, length: 11.56, width: 2.29, height: 2.54, img:"images/REEFER_20_40.png" },
  { id: 13, name: "High Cube - 40 PÉS", cbm: 76, maxWeight: 30480, length: 12.03, width: 2.35, height: 2.69, img:"images/HIGH_CUBE_40.png" },
  { id: 8, name: "Ventilado - 20 PÉS", cbm: 32.6, maxWeight: 28200, length: 5.9, width: 2.35, height: 2.39, img:"images/VENTILADO.png" },
  { id: 9, name: "Open Top - 20 PÉS", cbm: 65.6, maxWeight: 28200, length: 5.9, width: 2.35, height: 2.39, img:"images/OPEN_TOP_20_40.png" },
  { id: 10, name: "Open Top - 40 PÉS", cbm: 65.6, maxWeight: 30480, length: 12.03, width: 2.35, height: 2.39, img:"images/OPEN_TOP_20_40.png" },
  { id: 11, name: "Plataforma - 20 PÉS", cbm: 29, maxWeight: 34000, length: 5.9, width: 2.35, height: 2.39, img:"images/PLATAFORMA_20_40.png" },
  { id: 12, name: "Plataforma - 40 PÉS", cbm: 67, maxWeight: 45000, length: 12.03, width: 2.35, height: 2.39, img:"images/PLATAFORMA_20_40.png" },
  { id: 5, name: "Flat Rack - 20 PÉS", cbm: 34, maxWeight: 30000, length: 5.9, width: 2.35, height: 2.39, img:"images/FLAT_HACK_40_20.png" },
  { id: 6, name: "Flat Rack - 40 PÉS", cbm: 67, maxWeight: 45000, length: 12.03, width: 2.35, height: 2.39, img:"images/FLAT_HACK_40_20.png" },
];