// src/components/styled/StyledBox2.tsx
import { styled } from '@mui/material/styles';

const StyledBox2 = styled('div')(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  minWidth: "100%",
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "10px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.5)",
  backgroundSize: "cover",
  [theme.breakpoints.up("xs")]: {
    marginTop: theme.spacing(10),
    width: "90%",
    padding: 0,
  },
  [theme.breakpoints.up("md")]: {
    width: "70%",
    padding: 0,
  },
}));

export default StyledBox2;
