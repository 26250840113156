import React, { useState } from 'react';
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { packageTypes } from '../../data/packageTypes';

interface PackageSelectorProps {
  selectedPackage: number;
  selectedVariation: string;
  length?: number | string;
  width?: number | string;
  height?: number | string;
  weight: number | string;
  quantity: number | string;
  onSelectPackage: (event: SelectChangeEvent<number>) => void;
  onSelectVariation: (event: SelectChangeEvent<string>) => void;
  onLengthChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWidthChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHeightChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateFields: () => boolean;
}

const PackageSelector: React.FC<PackageSelectorProps> = ({
  selectedPackage,
  selectedVariation,
  length,
  width,
  height,
  weight,
  quantity,
  validateFields,
  onSelectPackage,
  onSelectVariation,
  onLengthChange,
  onWidthChange,
  onHeightChange,
  onWeightChange,
  onQuantityChange,
}) => {
  const selectedPackageData = packageTypes.find(p => p.id === selectedPackage);
  const isCustomVariation = selectedVariation === 'Customizado';

  const [errors, setErrors] = useState({
    length: '',
    width: '',
    height: '',
    weight: '',
    quantity: '',
  });

  const validate = () => {
    const newErrors = {
      length: isCustomVariation && onLengthChange && !length ? 'Este campo é obrigatório' : '',
      width: isCustomVariation && onWidthChange && !width ? 'Este campo é obrigatório' : '',
      height: isCustomVariation && onHeightChange && !height ? 'Este campo é obrigatório' : '',
      weight: !weight ? 'Este campo é obrigatório' : '',
      quantity: !quantity ? 'Este campo é obrigatório' : '',
    };
    console.log(selectedVariation)
    setErrors(newErrors);
    validateFields(); // Atualize o estado de validação no componente pai

    return Object.values(newErrors).every(error => error === '');
  };

  return (
    <Box>
      <FormControl fullWidth margin="normal">
        <InputLabel id="package-select-label">Tipo de Pacote</InputLabel>
        <Select
          labelId="package-select-label"
          value={selectedPackage}
          onChange={onSelectPackage}
          label="Tipo de Pacote"
        >
          {packageTypes.map(p => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedPackageData && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="variation-select-label">Variação</InputLabel>
          <Select
            labelId="variation-select-label"
            value={selectedVariation}
            onBlur={validate}
            onChange={onSelectVariation}
            label="Variação"
          >
            {selectedPackageData.variations.map(v => (
              <MenuItem key={v.size} value={v.size}>
                {v.size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {isCustomVariation && (
        <>
          <TextField
            label="Comprimento (cm)"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            variant="outlined"
            value={length}
            onChange={onLengthChange}
            onBlur={validate}
            fullWidth
            margin="normal"
            required
            error={!!errors.length}
            helperText={errors.length}
            type="number"
            inputMode="decimal"
          />
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            label="Largura (cm)"
            variant="outlined"
            value={width}
            onChange={onWidthChange}
            onBlur={validate}
            fullWidth
            margin="normal"
            required
            error={!!errors.width}
            helperText={errors.width}
            type="number"
            inputMode="decimal"
          />
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
            label="Altura (cm)"
            variant="outlined"
            value={height}
            onChange={onHeightChange}
            onBlur={validate}
            fullWidth
            margin="normal"
            required
            error={!!errors.height}
            helperText={errors.height}
            type="number"
            inputMode="decimal"
          />
        </>
      )}

      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
        }}
        label="Peso por Pacote (kg)"
        variant="outlined"
        value={weight}
        onChange={onWeightChange}
        onBlur={validate}
        fullWidth
        margin="normal"
        required
        error={!!errors.weight}
        helperText={errors.weight}
        type="number"
        inputMode="decimal"
      />

      <TextField
        InputProps={{
          endAdornment: <InputAdornment position="end">unidades</InputAdornment>,
        }}
        label="Quantidade"
        variant="outlined"
        value={quantity}
        onChange={onQuantityChange}
        onBlur={validate}
        fullWidth
        margin="normal"
        required
        error={!!errors.quantity}
        helperText={errors.quantity}
        type="number"
        inputMode="decimal"
      />
    </Box>
  );
};

export default PackageSelector;
