import React from 'react';
import { Box, Select, MenuItem, FormControl, ListItemIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

// Importe os arquivos SVG das bandeiras
import FlagEN from '../flags/en.svg';
import FlagPT from '../flags/pt.svg';
import FlagFR from '../flags/fr.svg';
import FlagES from '../flags/es.svg';
import FlagCN from '../flags/cn.svg';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedLanguage = event.target.value;

    // Atualiza o idioma no i18next
    i18n.changeLanguage(selectedLanguage);

    // Redireciona para a rota correspondente ao idioma
    navigate(`/${selectedLanguage}`);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 10,
        right: 10,
        zIndex: 1000,
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        padding: '0px',
        margin: 0
      }}
    >
      <FormControl sx={{ padding: '0px' }}>
        <Select
          size="small"
          value={i18n.language}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select language' }}
          sx={{ padding: '0px', justifyContent: "center", alignContent: "center", alignItems: "center" }}
        >
          <MenuItem value="en">
            <ListItemIcon style={{ minWidth: 0 }}>
              <img src={FlagEN} alt="English" style={{ width: 24 }} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem value="pt">
            <ListItemIcon style={{ minWidth: 0 }}>
              <img src={FlagPT} alt="Português" style={{ width: 24 }} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem value="fr">
            <ListItemIcon style={{ minWidth: 0 }}>
              <img src={FlagFR} alt="Français" style={{ width: 24 }} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem value="es">
            <ListItemIcon style={{ minWidth: 0 }}>
              <img src={FlagES} alt="Español" style={{ width: 24 }} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem value="cn">
            <ListItemIcon style={{ minWidth: 0 }}>
              <img src={FlagCN} alt="中文" style={{ width: 24 }} />
            </ListItemIcon>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default LanguageSelector;
