import { createTheme, alpha } from '@mui/material/styles';

const oceanBlue = {
  50: 'hsl(204, 100%, 96%)',
  100: 'hsl(204, 85%, 90%)',
  200: 'hsl(204, 70%, 80%)',
  300: 'hsl(204, 60%, 70%)',
  400: 'hsl(204, 50%, 60%)',
  500: 'hsl(204, 40%, 50%)',
  600: 'hsl(204, 35%, 40%)',
  700: 'hsl(204, 30%, 30%)',
  800: 'hsl(204, 25%, 20%)',
  900: 'hsl(204, 20%, 10%)',
};

const seaGreen = {
  50: 'hsl(160, 100%, 95%)',
  100: 'hsl(160, 80%, 85%)',
  200: 'hsl(160, 70%, 75%)',
  300: 'hsl(160, 60%, 65%)',
  400: 'hsl(160, 50%, 55%)',
  500: 'hsl(160, 40%, 45%)',
  600: 'hsl(160, 35%, 35%)',
  700: 'hsl(160, 30%, 25%)',
  800: 'hsl(160, 25%, 15%)',
  900: 'hsl(160, 20%, 10%)',
};

const fogGray = {
  50: 'hsl(210, 25%, 95%)',
  100: 'hsl(210, 20%, 90%)',
  200: 'hsl(210, 15%, 80%)',
  300: 'hsl(210, 10%, 70%)',
  400: 'hsl(210, 10%, 60%)',
  500: 'hsl(210, 9%, 50%)',
  600: 'hsl(210, 8%, 40%)',
  700: 'hsl(210, 7%, 30%)',
  800: 'hsl(210, 6%, 20%)',
  900: 'hsl(210, 5%, 10%)',
};

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: oceanBlue[300],
      main: oceanBlue[500],
      dark: oceanBlue[700],
      contrastText: fogGray[50],
    },
    secondary: {
      light: seaGreen[300],
      main: seaGreen[500],
      dark: seaGreen[700],
      contrastText: fogGray[50],
    },
    text: {
      primary: fogGray[900],
      secondary: fogGray[600],
    },
    background: {
      default: fogGray[50],
      paper: fogGray[100],
    },
    divider: alpha(fogGray[200], 0.5),
  },
  typography: {
    fontFamily: ['"Bitter", "sans-serif"'].join(','),
    h1: { fontSize: '2rem', fontWeight: 700 },
    h2: { fontSize: '1.75rem', fontWeight: 700 },
    h3: { fontSize: '1.5rem', fontWeight: 600 },
    h4: { fontSize: '1.25rem', fontWeight: 600 },
    h5: { fontSize: '1rem', fontWeight: 500 },
    h6: { fontSize: '0.875rem', fontWeight: 500 },
    body1: { fontSize: '1rem', fontWeight: 400 },
    body2: { fontSize: '0.875rem', fontWeight: 400 },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          padding: 8,
          overflow: 'clip',
          backgroundColor: fogGray[50],
          border: '1px solid',
          borderColor: fogGray[200],
          ':before': {
            backgroundColor: 'transparent',
          },
          '&:first-of-type': {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          '&:last-of-type': {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: 8,
          '&:hover': { backgroundColor: fogGray[100] },
          '&:focus-visible': { backgroundColor: 'transparent' },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          marginBottom: 20,
          border: 'none',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: false,
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          transition: 'all 100ms ease',
          '&:focus-visible': {
            outline: `3px solid ${alpha(oceanBlue[400], 0.5)}`,
            outlineOffset: '2px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 4,
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          transition: 'all 100ms ease',
          backgroundColor: fogGray[50],
          borderRadius: 4,
          border: `1px solid ${alpha(fogGray[200], 0.5)}`,
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingY: 1.5,
          paddingX: 0.5,
          border: '1px solid',
          borderColor: oceanBlue[100],
          fontWeight: 600,
          backgroundColor: oceanBlue[50],
          '&:hover': {
            backgroundColor: oceanBlue[500],
          },
          '&:focus-visible': {
            borderColor: oceanBlue[300],
            backgroundColor: oceanBlue[200],
          },
          '& .MuiChip-label': {
            color: oceanBlue[500],
          },
          '& .MuiChip-icon': {
            color: oceanBlue[500],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(fogGray[200], 0.8),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: 8,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: oceanBlue[500],
          '&:hover': {
            backgroundColor: alpha(oceanBlue[300], 0.3),
            borderColor: oceanBlue[200],
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          color: oceanBlue[700],
          fontWeight: 500,
          position: 'relative',
          textDecoration: 'none',
          '&::before': {
            content: '""',
            position: 'absolute',
            width: 0,
            height: '1px',
            bottom: 0,
            left: 0,
            backgroundColor: oceanBlue[500],
            opacity: 0.7,
            transition: 'width 0.3s ease, opacity 0.3s ease',
          },
          '&:hover::before': {
            width: '100%',
            opacity: 1,
          },
          '&:focus-visible': {
            outline: `3px solid ${alpha(oceanBlue[500], 1)}`,
            outlineOffset: '4px',
            borderRadius: '2px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingRight: 6,
          paddingLeft: 6,
          color: fogGray[700],
          fontSize: '0.875rem',
          fontWeight: 500,
          borderRadius: 10,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          width: 36,
          height: 24,
          padding: 0,
          transition: 'background-color 100ms ease-in',
          '&:hover': {
            '& .MuiSwitch-track': {
              backgroundColor: oceanBlue[600],
            },
          },
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              transform: 'translateX(13px)',
            },
          },
          '& .MuiSwitch-track': {
            borderRadius: 50,
          },
          '& .MuiSwitch-thumb': {
            boxShadow: '0 0 2px 2px hsla(220, 0%, 0%, 0.2)',
            backgroundColor: 'hsl(0, 0%, 100%)',
            width: 16,
            height: 16,
            margin: 2,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          textTransform: 'none',
          borderRadius: 4,
          fontWeight: 500,
        },
      },
    },
  },
});

export default theme;
