import i18n from './i18n';

const detectUserLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage; 
  const availableLanguages = ['en', 'pt', 'es'];

  const language = availableLanguages.includes(userLang.split('-')[0])
    ? userLang.split('-')[0]
    : 'en';

  i18n.changeLanguage(language);
};

detectUserLanguage();

export default detectUserLanguage;