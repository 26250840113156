import React from 'react';
import { Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ContainerVisualization from '../ContainerVisualization';
import { useTranslation } from 'react-i18next'; 

interface CalculationResultsProps {
  realWeightCost: number;
  volumetricWeightCost: number;
  finalCost: number;
  cubagePercentage: number;
  weightPercentage: number;
  error: string;
  onBack: () => void;
  onReset: () => void;
}

const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

const CalculationResults: React.FC<CalculationResultsProps> = ({
  realWeightCost,
  volumetricWeightCost,
  finalCost,
  cubagePercentage,
  weightPercentage,
  error,
  onBack,
  onReset,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {error && (
        <Typography variant="body2" color="error" style={{ marginTop: '20px' }}>
          {error}
        </Typography>
      )}
      {realWeightCost > 0 && volumetricWeightCost > 0 && (
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6" color="textSecondary">
            {t('valor_final_peso_real')}: {formatCurrency(realWeightCost)}
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {t('valor_peso_volumetrico')}: {formatCurrency(volumetricWeightCost)}
          </Typography>
          <Typography variant="h5" color="primary">
            {t('preco_final_maior_valor')}: {formatCurrency(finalCost)}
          </Typography>
          <Divider/>
          <Typography variant="h6" color="textSecondary">
            {t('percentual_uso_cubagem')}: {cubagePercentage.toFixed(2)}%
          </Typography>
          <Typography variant="h6" color="textSecondary">
            {t('percentual_uso_peso')}: {weightPercentage.toFixed(2)}%
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button variant="contained" color="secondary" onClick={onBack}>
              {t('voltar')}
            </Button>
            <Button variant="contained" color="primary" onClick={onReset}>
              {t('reiniciar')}
            </Button>
          </Box>
        </div>
      )}
    </>
  );
};

export default CalculationResults;
