import React, { useState } from 'react';
import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import CargoFormFields from '../../molecules/CargoFormFields';
import PackageSelector from '../../molecules/PackageSelector';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

interface Step3CargoInputProps {
  inputType: string;
  setInputType: (inputType: string) => void;
  cbm: string | number;
  length: string | number;
  width: string | number;
  height: string | number;
  weight: string | number;
  packageLength: string | number;
  packageWidth: string | number;
  packageHeight: string | number;
  packageWeight: string | number;
  packageQuantity: string | number;
  selectedPackage: number;
  selectedVariation: string;
  onCbmChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLengthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWidthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectPackage: (event: SelectChangeEvent<number>) => void;
  onSelectVariation: (event: SelectChangeEvent<string>) => void;
  onPackageLengthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPackageWidthChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPackageHeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPackageWeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPackageQuantityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCalculate: () => void;
  onBack: () => void;
}

const Step3CargoInput: React.FC<Step3CargoInputProps> = ({
  inputType,
  cbm,
  length,
  width,
  height,
  weight,
  packageLength,
  packageWidth,
  packageHeight,
  packageWeight,
  packageQuantity,
  selectedPackage,
  selectedVariation,
  onCbmChange,
  onLengthChange,
  onWidthChange,
  onHeightChange,
  onWeightChange,
  onSelectPackage,
  onSelectVariation,
  onPackageLengthChange,
  onPackageWidthChange,
  onPackageHeightChange,
  onPackageWeightChange,
  onPackageQuantityChange,
  setInputType,
  onCalculate,
  onBack,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [isValid, setIsValid] = useState(false);

  const onInputTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputType(value);
  };

  const validateFields = (): boolean => {
    const valid = (inputType === 'CBM' && !!cbm && !!weight) ||
      (inputType === 'Dimensoes' && !!length && !!width && !!height && !!weight) ||
      (inputType === 'Packages' && !!packageLength && !!packageWidth && !!packageHeight && !!packageWeight && !!packageQuantity) ||
      (inputType === 'Packages' && selectedVariation !== "" && selectedVariation !== t('customizado') && !!packageWeight && !!packageQuantity);
    setIsValid(valid);
    return valid;
  };

  const handleNext = () => {
    if (validateFields()) {
      setError(null);
      onCalculate();
    } else {
      setError(t('todos_campos_obrigatorios'));
    }
  };

  return (
    <>
      <FormControl component="fieldset" sx={{ alignItems: "center" }}>
        <RadioGroup row aria-label="inputType" name="inputType" value={inputType} onChange={onInputTypeChange}>
          <FormControlLabel value="CBM" control={<Radio />} label="CBM" />
          <FormControlLabel value="Dimensoes" control={<Radio />} label={t('dimensoes')} />
          <FormControlLabel value="Packages" control={<Radio />} label={t('pacotes')} />
        </RadioGroup>
      </FormControl>

      {inputType === 'CBM' && (
        <CargoFormFields
          cbm={cbm}
          weight={weight}
          onCbmChange={onCbmChange}
          onWeightChange={onWeightChange}
          validateFields={validateFields}
        />
      )}

      {inputType === t('dimensoes') && (
        <CargoFormFields
          length={length}
          width={width}
          height={height}
          weight={weight}
          onLengthChange={onLengthChange}
          onWidthChange={onWidthChange}
          onHeightChange={onHeightChange}
          onWeightChange={onWeightChange}
          validateFields={validateFields}
        />
      )}

      {inputType === t('pacotes') && (
        <PackageSelector
          selectedPackage={selectedPackage}
          selectedVariation={selectedVariation}
          onSelectPackage={onSelectPackage}
          onSelectVariation={onSelectVariation}
          length={packageLength}
          width={packageWidth}
          height={packageHeight}
          weight={packageWeight}
          quantity={packageQuantity}
          onLengthChange={onPackageLengthChange}
          onWidthChange={onPackageWidthChange}
          onHeightChange={onPackageHeightChange}
          onWeightChange={onPackageWeightChange}
          onQuantityChange={onPackageQuantityChange}
          validateFields={validateFields}
        />
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
        <Button variant="contained" color="secondary" onClick={onBack}>
          {t('voltar')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext} disabled={!isValid}>
          {t('calcular_custo')}
        </Button>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
    </>
  );
};

export default Step3CargoInput;
