// src/components/molecules/HeroClean.tsx
import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ColorTitle from "../atoms/ColorTitle";

interface HeroCleanProps {
  title1: string;
  title2: string;
  pageDescrip: string;
}

const HeroClean: React.FC<HeroCleanProps> = ({ title1, title2, pageDescrip }) => {
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat"
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 2, sm: 8 },
          pb: { xs: 1, sm: 1 },
        }}
      >
        <Stack
          spacing={2}
          sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
        >
          <ColorTitle text1={title1} text2={title2} CompH="h1" />
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            {pageDescrip}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default HeroClean;

