import React from 'react';
import { Box, Typography } from '@mui/material';
import { containerTypes } from '../../../data/containerTypes';
import ContainerSelector from '../../molecules/ContainerSelector';

interface ContainerDetailsProps {
  selectedContainer: number;
  setSelectedContainer: (id: number) => void;
}

const ContainerDetails: React.FC<ContainerDetailsProps> = ({ selectedContainer, setSelectedContainer }) => {
  const selectedContainerData = containerTypes.find(c => c.id === selectedContainer);

  if (!selectedContainerData) return null;

  return (
    <Box sx={{ alignItems: "center", alignContent: "center", mb: 4 }}>
      <Typography variant="h2" sx={{ textAlign: "center" }}>Selecione o Container</Typography>
      <ContainerSelector
        selectedContainer={selectedContainer}
        onSelectContainer={(e) => setSelectedContainer(Number(e.target.value))}
      />
      <Box sx={{ display: "flex", gap: 1, textAlign: "center", justifyContent: "space-evenly" }}>
        <Typography fontSize={"0.8rem"} variant="body1" sx={{ textAlign: "center" }}>Peso Max.<br /> {selectedContainerData.maxWeight} kg</Typography>
        <Typography fontSize={"0.8rem"} variant="body1">Volume Max.<br /> {selectedContainerData.cbm} CBM</Typography>
        <Typography fontSize={"0.8rem"} variant="body1">Comprimento<br /> {selectedContainerData.length} m</Typography>
        <Typography fontSize={"0.8rem"} variant="body1">Largura<br /> {selectedContainerData.width} m</Typography>
        <Typography fontSize={"0.8rem"} variant="body1">Altura<br /> {selectedContainerData.height} m</Typography>
      </Box>
    </Box>
  );
};

export default ContainerDetails;
