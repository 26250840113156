// src/components/molecules/CargoFormFields.tsx
import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';

interface CargoFormFieldsProps {
  cbm?: number | string;
  length?: number | string;
  width?: number | string;
  height?: number | string;
  weight: number | string;
  onCbmChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLengthChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWidthChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onHeightChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onWeightChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validateFields: () => boolean;
}

const CargoFormFields: React.FC<CargoFormFieldsProps> = ({
  cbm,
  length,
  width,
  height,
  weight,
  onCbmChange,
  onLengthChange,
  onWidthChange,
  onHeightChange,
  onWeightChange,
  validateFields,
}) => {
  const [errors, setErrors] = useState({
    cbm: '',
    length: '',
    width: '',
    height: '',
    weight: '',
  });

  const validate = () => {
    const newErrors = {
      cbm: onCbmChange && !cbm ? 'Este campo é obrigatório' : '',
      length: onLengthChange && !length ? 'Este campo é obrigatório' : '',
      width: onWidthChange && !width ? 'Este campo é obrigatório' : '',
      height: onHeightChange && !height ? 'Este campo é obrigatório' : '',
      weight: !weight ? 'Este campo é obrigatório' : '',
    };

    setErrors(newErrors);
    validateFields(); // Atualize o estado de validação no componente pai

    return Object.values(newErrors).every(error => error === '');
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", width: { xs: "100%", md: "100%" } }}>
      {onCbmChange && (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: 2 }}>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">CBM</InputAdornment>,
            }}
            label="Volume (CBM)"
            variant="outlined"
            value={cbm}
            onChange={onCbmChange}
            onBlur={validate}
            fullWidth
            margin="normal"
            required
            error={!!errors.cbm}
            helperText={errors.cbm}
                type="number"
            inputMode="decimal"
          />
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
            }}
            label="Peso (kg)"
            variant="outlined"
            value={weight}
            onChange={onWeightChange}
            onBlur={validate}
            fullWidth
            margin="normal"
            required
            error={!!errors.weight}
            helperText={errors.weight}
            
            
                type="number"

            inputMode="decimal"
          />
        </Box>
      )}
      {onLengthChange && (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: 2 }}>
            <TextField
              label="Comprimento (m)"
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              variant="outlined"
              value={length}
              onChange={onLengthChange}
              onBlur={validate}
              fullWidth
              margin="normal"
              required
              error={!!errors.length}
              helperText={errors.length}
              type="number"
              inputMode="decimal"
            />
            <TextField
              label="Largura (m)"
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              variant="outlined"
              value={width}
              onChange={onWidthChange}
              onBlur={validate}
              fullWidth
              margin="normal"
              required
              error={!!errors.width}
              helperText={errors.width}
              type="number"
              inputMode="decimal"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: 2 }}>
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              label="Altura (m)"
              variant="outlined"
              value={height}
              onChange={onHeightChange}
              onBlur={validate}
              fullWidth
              margin="normal"
              required
              error={!!errors.height}
              helperText={errors.height}
              type="number"
              inputMode="decimal"
            />
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
              }}
              label="Peso (kg)"
              variant="outlined"
              value={weight}
              onChange={onWeightChange}
              onBlur={validate}
              fullWidth
              margin="normal"
              required
              error={!!errors.weight}
              helperText={errors.weight}
              type="number"
              inputMode="decimal"
            />
          </Box></>
      )}

    </Box>
  );
};

export default CargoFormFields;
