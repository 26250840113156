import React from 'react';
import { Button, Typography, useTheme, useMediaQuery } from '@mui/material';
import ContainerDetails from './ContainerDetails';
import configUrl from '../../configs/configUrl';
import { Box } from '@mui/system';

interface ContainerData {
  id: number;
  name: string;
  cbm: number;
  maxWeight: number;
  length: number;
  width: number;
  height: number;
  img: string;
}

interface Step1ContainerSelectionProps {
  selectedContainer: number;
  setSelectedContainer: (id: number) => void;
  onNext: () => void;
  selectedContainerData?: ContainerData;
}

const Step1ContainerSelection: React.FC<Step1ContainerSelectionProps> = ({ selectedContainer, setSelectedContainer, onNext, selectedContainerData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <ContainerDetails selectedContainer={selectedContainer} setSelectedContainer={setSelectedContainer} />
      <Box sx={{display:"flex", justifyContent:"center"}}>
        <img
          src={`${configUrl.apiUrl}/${selectedContainerData?.img}`}
          alt={`Container do tipo ${selectedContainerData?.name} `}
          loading="lazy"
          style={{
            width: isMobile ? '100%' : '70%',
            height: "auto",
            borderRadius: 20,
            marginBottom:20,
            padding: "2px",
          }}
        /></Box>
      <Button variant="contained" color="primary" onClick={onNext}>
        Continuar
      </Button>
    </>
  );
};

export default Step1ContainerSelection;
