// src/components/atoms/ColorTitle.tsx
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface ColorTitleProps {
  text1: string;
  text2: string;
  fontS?: number;
  CompH?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const ColorTitle: React.FC<ColorTitleProps> = ({
  text1,
  text2,
  fontS = 2,
  CompH = "h2",
}) => {
  return (
    <Box
      sx={{ alignItems: "center" }}
    >
      <Typography
        fontSize={`${fontS}em`}
        variant="h2"
        component={CompH}
        fontWeight="700"
        sx={{
          alignItems: "center",
          textAlign: 'center',
        }}
      >
        {text1}
        <Typography
          fontSize="1em"
          fontWeight="800"
          component="span"
          variant="h2"
          sx={{
            fontSize: "inherit",
            textAlign: "center",
            color: "primary.main",
          }}
        >
          &nbsp;{text2}
        </Typography>
      </Typography>
    </Box>
  );
};

export default ColorTitle;
