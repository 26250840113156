import React, { useState, ChangeEvent } from 'react';
import { Box, Container, Button, Typography, SelectChangeEvent } from '@mui/material';
import Step1ContainerSelection from './Step1ContainerSelection';
import Step2PriceSelection from './Step2PriceSelection';
import Step3CargoInput from './Step3CargoInput';
import CalculationResults from './CalculationResults';
import StyledBox2 from '../../styled/StyledBox2';
import { containerTypes } from '../../../data/containerTypes';
import { packageTypes } from '../../../data/packageTypes';
import { useTranslation } from 'react-i18next';

const normalizeDecimalInput = (value: string): string => {
  let normalizedValue = value.replace(',', '.').replace(/[^0-9.]/g, '');
  const firstDotIndex = normalizedValue.indexOf('.');
  if (firstDotIndex !== -1) {
    normalizedValue = normalizedValue.substring(0, firstDotIndex + 1) + normalizedValue.substring(firstDotIndex + 1).replace(/\./g, '');
  }
  return normalizedValue;
};
const convertToMeters = (value: number): number => value / 100;

const LCLCalculator: React.FC = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);
  const [inputType, setInputType] = useState<string>('CBM');
  const [priceType, setPriceType] = useState<string>('tonnePerCubicMeter');
  const [cbm, setCbm] = useState<number | string>('');
  const [length, setLength] = useState<number | string>('');
  const [width, setWidth] = useState<number | string>('');
  const [height, setHeight] = useState<number | string>('');
  const [weight, setWeight] = useState<number | string>('');
  const [routeCost, setRouteCost] = useState<number | string>('');
  const [selectedContainer, setSelectedContainer] = useState<number>(1);
  const [selectedPackage, setSelectedPackage] = useState<number>(1);
  const [selectedVariation, setSelectedVariation] = useState<string>('');
  const [packageLength, setPackageLength] = useState<number | string>('');
  const [packageWidth, setPackageWidth] = useState<number | string>('');
  const [packageHeight, setPackageHeight] = useState<number | string>('');
  const [packageWeight, setPackageWeight] = useState<number | string>('');
  const [packageQuantity, setPackageQuantity] = useState<number | string>('');
  const [cost, setCost] = useState<number>(0);
  const [realWeightCost, setRealWeightCost] = useState<number>(0);
  const [volumetricWeightCost, setVolumetricWeightCost] = useState<number>(0);
  const [cubagePercentage, setCubagePercentage] = useState<number>(0);
  const [weightPercentage, setWeightPercentage] = useState<number>(0);
  const [error, setError] = useState<string>('');

  const selectedContainerData = containerTypes.find(c => c.id === selectedContainer);

  const onReset = () => {
    setStep(1)
    setCost(0);
    setSelectedPackage(0);
    setRouteCost(0);
    setLength(0);
    setCbm(0);
    setWidth(0);
    setWeight(0);
    setHeight(0);
    setRealWeightCost(0);
    setVolumetricWeightCost(0);
    setCubagePercentage(0);
    setWeightPercentage(0);
  }

  const calculateLCL = () => {
    setStep(4)
    if (!selectedContainerData) return;

    let volume = 0;
    let actualWeight = parseFloat(normalizeDecimalInput(weight.toString()));

    if (inputType === 'CBM') {
      volume = parseFloat(normalizeDecimalInput(cbm.toString()));
    } else if (inputType === 'Dimensoes') {
      volume = parseFloat(normalizeDecimalInput(length.toString())) *
        parseFloat(normalizeDecimalInput(width.toString())) *
        parseFloat(normalizeDecimalInput(height.toString()));
    } else if (inputType === 'Packages') {
      if (selectedVariation === t('customizado')) {
        volume = convertToMeters(parseFloat(normalizeDecimalInput(packageLength.toString()))) *
          convertToMeters(parseFloat(normalizeDecimalInput(packageWidth.toString()))) *
          convertToMeters(parseFloat(normalizeDecimalInput(packageHeight.toString())));
        actualWeight = parseFloat(normalizeDecimalInput(packageWeight.toString()));
      } else {
        const packageType = packageTypes.find(p => p.id === selectedPackage);
        const variation = packageType?.variations.find(v => v.size === selectedVariation);
        if (variation && variation.length && variation.width && variation.height) {
          volume = variation.length * variation.width * variation.height;
          actualWeight = parseFloat(normalizeDecimalInput(packageWeight.toString()));
        }
      }
      volume *= parseFloat(normalizeDecimalInput(packageQuantity.toString()));
      actualWeight *= parseFloat(normalizeDecimalInput(packageQuantity.toString()));
    }

    const containerCost = parseFloat(normalizeDecimalInput(routeCost.toString()));

    let costByVolume, costByWeight;
    if (priceType === 'container') {
      costByVolume = (volume / selectedContainerData.cbm) * containerCost;
      costByWeight = (actualWeight / selectedContainerData.maxWeight) * containerCost;
    } else {
      costByVolume = volume * containerCost;
      costByWeight = (actualWeight / 1000) * containerCost;
    }

    const volumetricWeight = volume * 1000;

    if (actualWeight > selectedContainerData.maxWeight) {
      setError(`${t('peso_excede')} (${selectedContainerData.maxWeight} kg).`);
      setCost(0);
      setRealWeightCost(0);
      setVolumetricWeightCost(0);
      setCubagePercentage(0);
      setWeightPercentage(0);
      return;
    }
    if (volume > selectedContainerData.cbm) {
      setError(`${t('volume_excede')} (${selectedContainerData.cbm} CBM).`);
      setCost(0);
      setRealWeightCost(0);
      setVolumetricWeightCost(0);
      setCubagePercentage(0);
      setWeightPercentage(0);
      return;
    }

    setRealWeightCost(costByWeight);
    setVolumetricWeightCost(costByVolume);

    const finalCost = Math.max(costByWeight, costByVolume);
    const cubageUsage = (volume / selectedContainerData.cbm) * 100;
    const weightUsage = (actualWeight / selectedContainerData.maxWeight) * 100;

    setCost(finalCost);
    setCubagePercentage(cubageUsage);
    setWeightPercentage(weightUsage);
    setError('');
  };


  return (
    <StyledBox2>
      <Container sx={{ display: "flex", flexDirection: "column", py: 5 }}>
        {step === 1 && (
          <>
            <Typography variant="h3" fontSize={"1em"} sx={{ mb: 2, textAlign: "center" }}>{t('etapa_1')}</Typography>

            <Step1ContainerSelection
              selectedContainerData={selectedContainerData}
              selectedContainer={selectedContainer}
              setSelectedContainer={setSelectedContainer}
              onNext={() => setStep(2)}
            />
          </>
        )}
        {step === 2 && (
          <>
            <Typography variant="h3" fontSize={"1em"} sx={{ mb: 2, textAlign: "center" }}>{t('etapa_2')}</Typography>
            <Step2PriceSelection
              priceType={priceType}
              routeCost={routeCost}
              onPriceTypeChange={(e: ChangeEvent<HTMLInputElement>) => setPriceType(e.target.value)}
              onRouteCostChange={(e: ChangeEvent<HTMLInputElement>) => setRouteCost(normalizeDecimalInput(e.target.value))}
              onNext={() => setStep(3)}
              onBack={() => setStep(1)}
            /></>
        )}
        {step === 3 && (
          <>
            <Typography variant="h3" fontSize={"1em"} sx={{ mb: 2, textAlign: "center" }}>{t('etapa_3')}</Typography>
            <Step3CargoInput
              inputType={inputType}
              setInputType={setInputType}
              cbm={cbm}
              length={length}
              width={width}
              height={height}
              weight={weight}
              selectedPackage={selectedPackage}
              selectedVariation={selectedVariation}
              packageLength={packageLength}
              packageWidth={packageWidth}
              packageHeight={packageHeight}
              packageWeight={packageWeight}
              packageQuantity={packageQuantity}
              onCbmChange={(e: ChangeEvent<HTMLInputElement>) => setCbm(normalizeDecimalInput(e.target.value))}
              onLengthChange={(e: ChangeEvent<HTMLInputElement>) => setLength(normalizeDecimalInput(e.target.value))}
              onWidthChange={(e: ChangeEvent<HTMLInputElement>) => setWidth(normalizeDecimalInput(e.target.value))}
              onHeightChange={(e: ChangeEvent<HTMLInputElement>) => setHeight(normalizeDecimalInput(e.target.value))}
              onWeightChange={(e: ChangeEvent<HTMLInputElement>) => setWeight(normalizeDecimalInput(e.target.value))}
              onSelectPackage={(e: SelectChangeEvent<number>) => setSelectedPackage(Number(e.target.value))}
              onSelectVariation={(e: SelectChangeEvent<string>) => setSelectedVariation(e.target.value)}
              onPackageLengthChange={(e: ChangeEvent<HTMLInputElement>) => setPackageLength(normalizeDecimalInput(e.target.value))}
              onPackageWidthChange={(e: ChangeEvent<HTMLInputElement>) => setPackageWidth(normalizeDecimalInput(e.target.value))}
              onPackageHeightChange={(e: ChangeEvent<HTMLInputElement>) => setPackageHeight(normalizeDecimalInput(e.target.value))}
              onPackageWeightChange={(e: ChangeEvent<HTMLInputElement>) => setPackageWeight(normalizeDecimalInput(e.target.value))}
              onPackageQuantityChange={(e: ChangeEvent<HTMLInputElement>) => setPackageQuantity(normalizeDecimalInput(e.target.value))}
              onCalculate={calculateLCL}
              onBack={() => setStep(2)}
            /></>
        )}
        {step === 4 && (
          <>
            <Typography variant="h3" fontSize={"1em"} sx={{ mb: 2, textAlign: "center" }}>{t('resultado')}</Typography>
            <CalculationResults
              realWeightCost={realWeightCost}
              volumetricWeightCost={volumetricWeightCost}
              finalCost={cost}
              cubagePercentage={cubagePercentage}
              weightPercentage={weightPercentage}
              error={error}
              onBack={() => setStep(3)}
              onReset={() => onReset()}
            />
          </>
        )}
      </Container>
    </StyledBox2>
  );
};

export default LCLCalculator;
